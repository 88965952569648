<template>
  <GenericModal ref="modal" :name="callback_title" css-class="inquiry-modal">
    <template v-if="form_builder_activated">
      <FormBuilderSection :is-in-popin="true" :blok="type === 'callback' ? request_a_call[0] : request_a_visit[0]" :facility="facility" />
    </template>
    <template v-else>
      <template v-if="!isSent">
        <Form v-show="!sendError" v-slot="{ errors }" @submit="handleSubmit">
          <div class="header">
            <template v-if="type === 'callback'">
              <h2 class="size-xl">{{ callback_title }}</h2>
              <p class="size-xs">{{ callback_description }}</p>
            </template>
            <template v-else>
              <h2 class="size-xl">{{ visit_title }}</h2>
              <p class="size-xs">
                {{
                  useStringParams(visit_description, {
                    facilityName: facility?.commercialName,
                  })
                }}
              </p>
            </template>
          </div>

          <div class="inquiry-information-section">
            <p class="size-2xs">{{ required_fields_mention }}</p>
            <div class="fields-list">
              <template v-if="type === 'callback'">
                <FormSelect
                  v-if="callback_purposes?.length"
                  :id="prefixId('callback-purpose')"
                  name="request"
                  :label="callback_purpose_select_label"
                  :options="callback_purposes.map((purpose) => purpose.label)"
                  required
                />

                <FormSelect
                  v-if="callback_timeslots?.length"
                  :id="prefixId('callback-timeslot')"
                  name="timeSlot"
                  :label="callback_timeslot_select_label"
                  :options="callback_timeslots.map((timeslot) => timeslot.label)"
                  required
                />
              </template>
              <template v-else>
                <FormSelect
                  v-if="visit_timeslots?.length"
                  :id="prefixId('visit-timeslot')"
                  name="preferredDay"
                  :label="visit_timeslot_select_label"
                  :options="visit_timeslots.map((timeslot) => timeslot.label)"
                  required
                />
              </template>
            </div>
          </div>

          <div class="user-information-section">
            <div class="user-information-header">
              <h2 class="size-m">{{ user_information_title }}</h2>
              <p class="user-information-description size-2xs">
                {{ user_information_description }}
              </p>
            </div>
            <div class="fields-list flex-2-per-row">
              <FormInput
                :id="prefixId('firstname')"
                name="firstName"
                :label="user_firstname_input_label"
                type="text"
                max-length="50"
                rules="required|alpha"
                required
              />
              <FormInput
                :id="prefixId('lastname')"
                name="lastName"
                :label="user_lastname_input_label"
                type="text"
                max-length="50"
                rules="required|alpha"
                required
              />
              <FormInput
                v-if="type === 'visit'"
                :id="prefixId('email')"
                name="email"
                :label="user_mail_input_label"
                type="email"
                required
              />
              <FormInput
                :id="prefixId('phone')"
                name="phone"
                :label="user_phone_input_label"
                type="tel"
                max-length="15"
                required
              />
              <FormTextarea
                v-if="type === 'visit'"
                :id="prefixId('more-info')"
                name="message"
                :label="visit_more_info_input_label"
                rows="4"
              />
            </div>
          </div>

          <div class="terms-and-conditions-section size-2xs">
            <div v-if="privacyExplanation?.length" v-html="privacyExplanation" />
            <FormCheckbox
              :id="prefixId('terms-and-conditions')"
              name="cguContent"
              :label="termsAndConditionsLabel"
              required
            />
          </div>

          <FormCaptcha
            v-if="turnstileSitekey && modal.isOpen"
            ref="captcha"
            class="captcha"
          />

          <SimpleButton
            class="send-button"
            solid
            xl
            :disabled="isSending || !!errors['captchaToken']"
          >
            {{ send_button_label }}
          </SimpleButton>
        </Form>
        <FormResponse
          v-if="sendError"
          :title="sbError?.title || sendError.message"
          :description="sbError?.description"
          :cta-label="error_cta_label"
          errored
          @click="backToForm"
        />
      </template>
      <FormResponse
        v-else
        :title="confirmation_title"
        :description="confirmation_description"
        :cta-label="confirmation_cta_label"
        @click="closeModal"
      />
    </template>
  </GenericModal>
</template>

<script setup>
import { Form } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
import countries from "i18n-iso-countries";
import { useSnakeCaseFilter } from "@/composables/SnakecaseFilter";
import { useTrackingStore } from "@/stores/tracking";

const trackingStores = useTrackingStore();

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: "callback",
    validator(value) {
      return ["callback", "visit"].includes(value);
    },
  },
  facility: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const modal = ref(null);
const isSent = ref(false);

const captcha = ref(null);

const closeModal = () => {
  modal.value && modal.value.closeModal();
  tracking({
    event: "click.navigation",
    click: "close",
    click_chapter1: "popin",
    form_name:
      props.type === "callback"
        ? useSnakeCaseFilter(callback_title.value)
        : useSnakeCaseFilter(visit_title.value),
    form_type: "popin_form",
  });
};
const openModal = () => {
  isSent.value = false;
  modal.value && modal.value.openModal();
  tracking({
    event: "popin.display",
    form_name:
      props.type === "callback"
        ? useSnakeCaseFilter(callback_title.value)
        : useSnakeCaseFilter(visit_title.value),
    form_type: "popin_form",
  });
};
defineExpose({
  openModal,
  closeModal,
});

const config = useRuntimeConfig();
const turnstileSitekey = config.public["turnstileSitekey"];
let story;
try {
  story = await useAsyncStoryblok(config.public["storyblokCountryPrefix"] + "/forms", {
    version: config.public["storyblokVersion"],
  });
} catch (error) {
  console.error(error);
}

const {
  user_information_title,
  user_information_description,
  user_firstname_input_label,
  user_lastname_input_label,
  user_phone_input_label,
  user_mail_input_label,
  send_button_label,
  privacy_explanation,
  terms_and_conditions_label,
  required_fields_mention,
  callback_title,
  callback_description,
  callback_purpose_select_label,
  callback_purposes,
  callback_timeslot_select_label,
  callback_timeslots,
  visit_title,
  visit_description,
  visit_timeslot_select_label,
  visit_timeslots,
  visit_more_info_input_label,
  confirmation_title,
  confirmation_description,
  confirmation_cta_label,
  form_builder_activated,
  request_a_call,
  request_a_visit,
  error_cta_label,
  errors: sbErrors,
} = toRefs(story.value.content);

const privacyExplanation = useRenderRichText(privacy_explanation.value);
const termsAndConditionsLabel = useRenderRichText(
  terms_and_conditions_label.value
);

const query = gql`
  mutation createLead($leadData: LeadInput!) {
    firstLead: insertLead(leadData: $leadData) {
      uuid
      locale
      tracking
      formType
      facilityUniqueKey
      firstName
      lastName
      email
      phone
      timeSlot
      preferredDay
      request
      cguContent
      createdAt
      updatedAt
      message
    }
  }
`;

const {
  mutate: sendData,
  error: sendError,
  onDone: onSendSuccess,
  onError: onSendError,
  loading: isSending,
} = useMutation(query);

const tracking = (data) => {
  trackingStores.sendTrackingData(data);
};

const handleSubmit = (values) => {
  const formType =
    props.type === "visit"
      ? "REQUEST_VISIT"
      : props.facility?.facilityUniqueKey
      ? "REQUEST_CALL_FACILITY"
      : "REQUEST_CALL";

  const locale = countries
    .getName(config.public["country"], "en")
    .toUpperCase();

  const variables = {
    leadData: {
      ...values,
      locale,
      formType,
      facilityUniqueKey: props.facility?.facilityUniqueKey,
      tracking: window.location.href,
    },
  };
  sendData(variables);
  captcha.value?.reset();
};

onSendSuccess(() => {
  isSent.value = true;
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(send_button_label.value),
    click_chapter1: 'popin',
    click_chapter2: 'CTA',
    form_name:
      props.type === 'callback'
        ? useSnakeCaseFilter(callback_title.value)
        : useSnakeCaseFilter(visit_title.value),
    form_type: 'popin',
  })
});

onSendError((error) => {
  console.error(error);
});

const sbError = computed(() => {
  return sbErrors.value.find((error) => error.id === sendError.value?.message);
});

const backToForm = () => {
  isSent.value = false;
  sendError.value = null;
};

const uuid = ref(uuidv4());

const prefixId = (value) => {
  return `${uuid.value}-${value}`;
};
</script>

<style lang="scss">
.inquiry-modal.modal {
  z-index: $z-index-modal + 1;
}
</style>

<style lang="scss" scoped>
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include for-tablet-landscape-up {
    gap: 4rem;
  }

  div {
    z-index: initial;
  }
}

.header,
.fields-list,
.user-information-section,
.user-information-header,
.inquiry-information-section,
.terms-and-conditions-section {
  display: flex;
  flex-direction: column;
}
.header,
.user-information-header {
  gap: 0.5rem;
  text-align: center;
}
.header {
  @include for-tablet-landscape-up {
    gap: 1rem;
  }
}
.fields-list {
  $field-gap: 0.625rem;
  gap: $field-gap;

  &.flex-2-per-row {
    @include for-tablet-landscape-up {
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        flex: calc(50% - $field-gap);
      }
    }
  }
}

.user-information-section {
  gap: 1.5rem;
  margin-top: -0.725rem;

  @include for-tablet-landscape-up {
    gap: 2.5rem;
  }
}
.inquiry-information-section {
  gap: 1rem;

  @include for-tablet-landscape-up {
    gap: 1.5rem;
  }
}
.terms-and-conditions-section {
  gap: 0.75rem;
}
.send-button {
  margin: 0 auto;
}
.user-information-description {
  color: var(--text-70);
}
.captcha {
  margin-top: -3rem;

  @include for-tablet-landscape-up {
    margin-top: -4rem;
  }
}
:deep(.form-builder-item .description) {
  margin-top: 2rem;

  &:has(+ .form-builder-group) {
    text-align: center;

    h2 {
      margin-bottom: 1rem;
    }
  }
}
</style>
